<template>
  <div>
    <van-dialog
      v-model="show"
      show-cancel-button
      confirm-button-text="同意"
      cancel-button-text="返回"
      confirm-button-color="#06f"
      @confirm="confirm"
    >
      <div style="text-align: center; height: 50px; line-height: 50px; font-weight: 600">个人照片采集授权书</div>
      <div style="padding: 0 20px 20px 20px; max-height: 300px; overflow: auto">
        <div>1. sdf sdf sdf sdf sdf 水电费水电费水电费水电费</div>
        <div>1. sdf sdf sdf sdf sdf 水电费水电费水电费水电费</div>
        <div>1. sdf sdf sdf sdf sdf 水电费水电费水电费水电费</div>
        <div>1. sdf sdf sdf sdf sdf 水电费水电费水电费水电费</div>
        <div>1. sdf sdf sdf sdf sdf 水电费水电费水电费水电费</div>
        <div>1. sdf sdf sdf sdf sdf 水电费水电费水电费水电费</div>
        <div>1. sdf sdf sdf sdf sdf 水电费水电费水电费水电费</div>
        <div>1. sdf sdf sdf sdf sdf 水电费水电费水电费水电费</div>
        <div>1. sdf sdf sdf sdf sdf 水电费水电费水电费水电费</div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    }
  }
}
</script>