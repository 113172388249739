<template>
  <div class="taskDetails" v-if="show">
    <van-form @submit="onSubmit" ref="formData">
      <van-nav-bar :title="this.$store.state.taskTitle" left-arrow @click-left="onClickLeft"> </van-nav-bar>
      <div
        class="posBox"
        :style="{
          background: posError || this.$store.state.lng == '' ? '#fdeceb' : '#FFFBE6',
          color: posError || this.$store.state.lng == '' ? '#ff4d4f' : '#FAAD14'
        }"
      >
        <!-- justify-content: space-between; -->
        <div class="flexBox">
          <div>
            <span>
              <van-icon class="location-o" name="location-o" style="margin-right: 4px" />
              <span>{{ this.$store.state.nowAddress || '未获取到定位信息' }}</span>
            </span>
            <div style="padding-left: 21px" v-if="this.$store.state.lng !== 0">
              {{ showDistance() }}
            </div>
          </div>
          <van-button
            v-if="posError || this.$store.state.lng == ''"
            @click="browser"
            type="default"
            :loading="false"
            class="btn"
            loading-text="定位中..."
            native-type="button"
            style="position: relative; top: 4px"
          >
            <div class="flexBox">
              <img
                src="@/assets/icon/2-刷新.svg"
                alt=""
                style="height: 15px; width: 15px; margin-right: 2px; position: relative; top: 3px"
              />
              <span style="white-space: nowrap">位置异常</span>
            </div>
          </van-button>
        </div>
      </div>
      <div
        :style="{
          overflow: 'auto',
          height: height,
          'padding-bottom': '65px'
        }"
      >
        <div>
          <div class="cardBox" style="padding-right: 0">
            <div style="padding-right: 5.3%">
              <span class="required" v-if="$store.state.taskDetailsInfo.must">*</span>
              <span class="tittleBox">{{ $store.state.taskDetailsInfo.title }}</span>
              <span class="tittleBox" style="margin-left: 5px"> (￥{{ $store.state.taskDetailsInfo.price }}) </span>
            </div>
            <div style="padding-right: 5.3%; margin-top: 7px">
              <span v-if="$store.state.taskDetailsInfo.perfromStatus == 0">全天可执行</span>
              <span v-else-if="$store.state.taskDetailsInfo.perfromStatus == 1"
                >{{ $store.state.taskDetailsInfo.performStartTime }}~{{
                  $store.state.taskDetailsInfo.performEndTime
                }}可执行</span
              >
              <div class="tips" v-if="$store.state.taskDetailsInfo.isDone">已完成</div>
              <div class="tipsnotstart" v-else-if="$store.state.taskDetailsInfo.taskStatus === 1">未开始</div>
              <div class="tipsend" v-else-if="$store.state.taskDetailsInfo.taskStatus === 2">已结束</div>
              <div class="tipsgoon" v-else-if="$store.state.taskDetailsInfo.taskStatus === 3">进行中</div>
            </div>
            <div
              class="describe"
              style="margin-top: 10px; padding-right: 5.3%"
              v-if="$store.state.taskDetailsInfo.memo"
            >
              {{ $store.state.taskDetailsInfo.memo }}
            </div>
          </div>
        </div>
        <!-- <pre>{{ dataList }}</pre> -->

        <div v-for="(item, index) in dataList" :key="item.sort">
          <!-- {{ item }} -->
          <div class="cardBox">
            <div class="flexBox" style="margin-bottom: 15px">
              <div class="indexBox">{{ index + 1 }}、</div>
              <div class="taskTittle" style="width: 100%">
                {{ item.title }}
                <div style="font-size: 13px; color: #999; margin-top: 2px">
                  <div style="display: flex; justify-content: space-between">
                    <div style="color: #ff4d4f; font-size: 14px">
                      <!-- 如果旧数据图片类型没有sampleText的话  显示'请上传1张照片' -->
                      <span>{{ item.sampleText || (item.itemType === 3 ? '请上传1张照片' : '') }}</span>
                    </div>
                    <div class="example" v-if="item.sampleImg">
                      <span @click="imgPre(item.sampleImg)">查看示例</span>
                      <img src="@/assets/icon/1-右.svg" alt="" class="arrow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item.itemType == 0 || item.itemType == 5">
              <!-- code是每道题的code  @changeText="changeText"-->
              <TextField :item="item" :code="item.code" />
            </div>
            <div v-else-if="item.itemType == 1">
              <RadioField :item="item" :code="item.code" :index="index" @changeRadio="changeRadioBox" />
            </div>
            <div v-else-if="item.itemType == 2">
              <CheckField :item.sync="dataList[index]" :itemOptionCode="item.itemOptionCode" :code="item.code" :index="index" @changeCheck="changeCheckBox" />
            </div>
            <!-- 20230206 移除itemImg字段全部使用 itemImgs -->
            <!-- <div v-else-if="item.itemType == 3 && item.itemTypeF !== 999">
              <UploadField :item.sync="dataList[index]" :code="item.code" />
            </div> -->
            <!-- 20230206 移除itemImg字段全部使用 itemImgs -->
            <!-- <div v-else-if="item.itemTypeF === 999"> -->
            <div v-else-if="item.itemType === 3">
              <UploadFields :item.sync="dataList[index]" :code="item.code" @clickImgsUpload="clickImgsUpload" />
            </div>
          </div>
        </div>
      </div>
      <div class="btnBox">
        <van-button class="cancel" type="default" @click="onClickLeft" native-type="button"> 取消 </van-button>
        <van-button
          class="submit"
          type="primary"
          :disabled="computedDisabled()"
          :class="computedDisabled() ? 'disabledSub' : ''"
          native-type="submit"
        >
          确定
        </van-button>
      </div>
    </van-form>
    <van-action-sheet
      v-model="showRegisterPhoto"
      title="个人照片采集和使用授权"
      :close-on-click-overlay="false"
      :closeable="false"
    >
      <div style="padding: 20px 10.7% 20px 10.7%; font-size: 14px">
        <div style="font-size: 14px; color: #666666; text-align: justify; line-height: 20px; text-indent: 2em">
          为了加强个人信息校验，保障您的权益，您将授权米咖采集和使用您的任务形象照和任务证件照，以确保您提交的任务数据的真实性。基于任务结算目的，我们可能会向品牌方共享您的任务形象照和任务证件照，如您不同意共享此信息，则可能影响该任务的结算
        </div>
      </div>
      <div style="padding: 0 4%">
        <!--  :disabled="!checkedRegisterPhoto" -->
        <van-button type="primary" style="border-radius: 22px; width: 100%" @click="confirmRegister"> 授权 </van-button>
        <van-button style="border: none; width: 100%; color: #1790ff" type="default" @click="onClickLeft('refuse')">
          暂不授权
        </van-button>
      </div>
    </van-action-sheet>
    <AgreementPhoto ref="AgreementPhoto" @confirm="confirmRegister" />
  </div>
</template>
<script>
import { Toast, Dialog, ImagePreview } from 'vant'
import {
  listSubTaskDetail,
  reportSubTaskDetail,
  addHardwareLog,
  checkAccordProvide,
  accordProvide,
  getFace
} from '@/api/api.js'
import {
  browserPos
  // , getDistance
} from '@/api/baiduMap.js'
import TextField from '@/components/taskDetails/TextField.vue'
import CheckField from '@/components/taskDetails/CheckField.vue'
import RadioField from '@/components/taskDetails/RadioField.vue'
import UploadField from '@/components/taskDetails/UploadField.vue'
import UploadFields from '@/components/taskDetails/UploadFields.vue'
import AgreementPhoto from '@/components/taskDetails/AgreementPhoto.vue'
import moment from 'moment'
import UA from 'ua-device'
// import mock from '@/mock/mock.js'

export default {
  components: {
    TextField,
    RadioField,
    CheckField,
    UploadField,
    AgreementPhoto,
    UploadFields
  },
  data() {
    return {
      title: '', // 模块名称
      posError: true, // 定位是否异常
      height: '500px',
      dataList: [], // 进行逻辑处理后的展示数据
      dataListString: '', // dataList 进行 stringify 用于比对禁用状态
      keepDataList: [], // 未进行逻辑处理前的完整数据 为了保留答案 注意转换过程不要使用深拷贝和非变异方法
      logic: [], // 逻辑数组
      latitude: 0, // 纬度
      longitude: 0, // 经度
      errAddress: '', // 坐标异常的时候要显示地址
      disabledList: [], // 用于存储是否下面每一道题都有答案
      defaultPhoneHeight: '', // 屏幕默认高度
      nowPhoneHeight: '', // 屏幕现在的高度
      posCurrent: 3, // 如果连续调用三次定位扔没有返回结果取前端腾讯定位传过来的定位信息
      showRegisterPhoto: false, // 照片收取弹窗
      checkedRegisterPhoto: false, // 协议的复选框
      show: false, //
      oldDataList: [] // 保存原始数据，方便重置使用
    }
  },
  methods: {
    // 查看示例
    imgPre(sampleImg) {
      // +`&${new Date().getTime()}`
      ImagePreview([sampleImg])
    },
    onClickLeft(type) {
      if (type === 'refuse') {
        // location.href = 'javascript:history.back();'
        this.clearCache()
        this.$router.push('/entrance')
        return
      }
      if (!this.checkChange()) {
        this.clearCache()
        this.$router.push('/entrance')
      } else {
        Dialog.confirm({
          title: '提示',
          message: '如未提交取消后下次需要重新回答所有问题，确认要取消吗？',
          confirmButtonText: '确认',
          cancelButtonText: '继续做任务',
          cancelButtonColor: '#ee0a24',
          confirmButtonColor: 'black'
        })
          .then(() => {
            // location.href = 'javascript:history.back();'
            this.clearCache()
            this.$router.push('/entrance')
          })
          .catch(() => {
            // on cancel
          })
      }
    },
    clearCache() {
      this.$store.commit('taskDetailsCache', { performCode: '', dataList: [], keepDataList: [] })
    },
    clickImgsUpload() {
      // 将keepDataList的数据与dataList的数据同步防止数据不一致
      const result = this.keepDataList.map((item) => {
        const newItem = this.dataList.find((newItem) => newItem.code === item.code)
        return newItem || item
      })
      this.$store.commit('taskDetailsCache', {
        performCode: this.$route.params.code,
        dataList: this.dataList,
        keepDataList: result
      })
    },
    /**
     * 找到目标题目后面与目标题目有逻辑关联的题目，并重置
     * @param {*} target 目标题目
     */
    resetRestValue(target) {
      if (!this.oldDataList || !this.oldDataList.length) return
      console.log('----- 重置 ------')
      // 目标题目索引
      const targetIndex = this.keepDataList.findIndex((v) => v.code === target.code)
      for (let i = targetIndex + 1; i < this.keepDataList.length; i++) {
        // 剩余题目
        const restItem = this.keepDataList[i]
        for (const logicItem of this.logic) {
          // 找到逻辑关联的题目并重置
          if (logicItem[target.code]?.logicItemCode?.includes(restItem.code)) {
            const data = JSON.parse(JSON.stringify(this.oldDataList[i]))
            // 递归清除
            this.resetRestValue(data)
            data.itemOptionCode = ''
            data.itemAnswer = ''
            data.itemImgs = []
            if (data.itemType == 3) {
              data.itemImgs = [{ absoluteUrl: '', relativeUrl: '', key: 'init0' }]
            }
            if (data.itemType == 0 || data.itemType == 5) {
              data.itemAnswer = ''
            }
            console.log('data: ', data)
            this.keepDataList[i] = data
          }
        }
      }
    },
    // 改变多选框值
    changeCheckBox(item, val) {
      console.log('改变了 checkbox')
      // console.log('changeCheckBox', item, val, index)
      // keepDataList下的值也同时改变 避免计算逻辑时 逻辑数组中去了keepList的值
      const obj = this.keepDataList.find((el) => el.code === item.code)
      if (obj) {
        obj.itemOptionCode = val
      }
      this.resetRestValue(item)
      this.dataList = this.getListByLogic(this.keepDataList, this.logic)
    },
    // 改变单选框值
    changeRadioBox(item, val) {
      console.log('改变了 radiobox')
      const obj = this.keepDataList.find((el) => el.code === item.code)
      if (obj) {
        obj.itemOptionCode = val
      }
      this.resetRestValue(item)
      this.dataList = this.getListByLogic(this.keepDataList, this.logic)
    },
    // changeText(item, val) {
    // 如果调用逻辑则下面这段需要打开 否则不用因为在缓存keepDataList前已经执行了一次了
    // const obj = this.keepDataList.find((el) => el.code === item.code)
    // if (obj) {
    //   obj.itemAnswer = val
    // }
    // },
    // 进行定位
    browser() {
      this.posCurrent--
      browserPos(this.posCurrent)
    },
    // 提交表单
    async onSubmit(values) {
      // 校验是否同时打开了多个网页导致缓存数据异常
      await this.checkStoreDetail()
      // 提交时先校验是否需要刷脸
      // .......................
      const taskSubId = this.$route.params.taskSubId
      const performCode = this.$route.params.code
      const userCode = this.$store.state.userInfo.userCode
      const res = await getFace({ taskSubId, performCode, userCode }).catch(() => {

      })
      if (!res.data.data.isPassed) {
        Dialog.alert({
          title: '提示',
          message: '当前执行激活任务的时间已过期,请重新进行激活任务',
          confirmButtonText: '前往激活',
          confirmButtonColor: '#24ADFE'
        }).then(() => {
          this.toFace(res.data.data.faceCompareUrl, res.data.data.msgText, res.data.data.protocolState)
        })
        return
      }
      // 调用提交接口
      const reportDetails = this.dataList.map((elem) => {
        if (elem.itemType === 0 || elem.itemType === 5) {
          return {
            taskSubItemCode: elem.code,
            itemType: elem.itemType,
            itemAnswer: values[elem.code],
            taskSubItemName: elem.title
            // 加上每道题的名称 taskSubItemName
          }
        }
        if (elem.itemType === 1) {
          return {
            taskSubItemCode: elem.code,
            itemType: elem.itemType,
            itemOptionCode: values[elem.code],
            taskSubItemName: elem.title
          }
        }
        if (elem.itemType === 2) {
          return {
            taskSubItemCode: elem.code,
            itemType: elem.itemType,
            itemOptionCode: values[elem.code].join('#'),
            taskSubItemName: elem.title
          }
        }
        // 此处需要对itemType === 3 && itemTypeF === 999 多图片题变没变都传
        // 20230206 移除itemImg字段全部使用 itemImgs
        // if (elem.itemType === 3 && elem.itemTypeF === 999) {
        if (elem.itemType === 3) {
          const itemImgs = elem.itemImgs.filter((el) => el.relativeUrl)
          return {
            taskSubItemCode: elem.code,
            itemType: elem.itemType,
            taskSubItemName: elem.title,
            itemImgList: itemImgs,
            imgMinCount: elem.imgMinCount,
            imgMaxCount: elem.imgMaxCount
          }
        }
        // 20230206 移除itemImg字段全部使用 itemImg
        // else if (elem.itemType === 3) {
        //   //单图片 未变更过得题不传
        //   let obj = {}
        //   obj.taskSubItemCode = elem.code
        //   obj.itemType = elem.itemType
        //   obj.taskSubItemName = elem.title
        //   if (values[elem.code][0].reUrl) {
        //     obj.itemImg = values[elem.code][0].reUrl
        //   } else {
        //     obj.del = true
        //   }
        //   return obj
        // }
      })
      // 20230206 移除itemImg字段全部使用 itemImg
      // reportDetails = reportDetails.filter((item, index) => {
      //   return item.del !== true
      // })

      // console.log('提交的数据', {
      //   reportDetails: reportDetails
      // })
      // console.log(
      //   'reportPrice',
      //   this.dataList[0].isEarlyEndAnswer === true ? 0 : this.$store.state.taskDetailsInfo.price
      // )
      // return

      const params = {
        userCode: this.$store.state.userInfo.userCode, // 用户编码
        userName: this.$store.state.userInfo.userName, // 用户名称
        userOpenid: this.$store.state.userInfo.userOpenid, // 用户微信openid
        userName: this.$store.state.userInfo.userName, // 用户名称
        projectCode: this.$store.state.userInfo.projectCode, // 项目编码
        projectName: this.$store.state.userInfo.projectName, // 项目名称
        terminalCode: this.$store.state.userInfo.terminalCode, // 终端编码
        terminalName: this.$store.state.userInfo.terminalName, // 终端名称
        terminalProvince: this.$store.state.userInfo.terminalProvince, // 终端省
        terminalCity: this.$store.state.userInfo.terminalCity, // 终端市
        terminalArea: this.$store.state.userInfo.terminalArea, // 终端区
        terminalTown: this.$store.state.userInfo.terminalTown, // 终端镇
        terminalLongitude: this.$store.state.userInfo.terminalLongitude, // 终端经度
        terminalLatitude: this.$store.state.userInfo.terminalLatitude, // 终端纬度
        batchCode: this.$store.state.batchCode, // 批次编码------------------------------>暂未获取入口处写死的
        taskPerformCode: this.$route.params.code, // 任务执行编码
        taskSubCode: this.$store.state.taskDetailsInfo.taskSubCode, // 子任务编码
        taskSubName: this.$store.state.taskDetailsInfo.title, // 加子任务名称
        taskProjectCode: this.$store.state.taskCode, // 加模块编码
        taskProjectName: this.$store.state.taskTitle, // 加模块名称
        reportDate: this.$store.state.userInfo.attdate, // 上报日期
        reportTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), // 提报时间
        reportPrice: this.dataList[0].isEarlyEndAnswer === true ? 0 : this.$store.state.taskDetailsInfo.price, // 任务定价  甄别结束的时候价钱传0  正常结束正常传钱
        finishType: this.dataList[0].isEarlyEndAnswer === true ? 1 : 0, // 结束状态 甄别结束传1 其余传0
        reportLongitude: this.$store.state.lng, // 提报经度
        reportLatitude: this.$store.state.lat, // 提报纬度
        reportDistance: this.$store.state.distance, // 距离
        appCode: 'mika', // 应用
        tenCode: this.dataList[0].tenCode, // 租户code
        // ↓上报明细
        reportDetails: reportDetails,
        performStartTime: this.$store.state.taskDetailsInfo.performStartTime, // 任务执行开始时间
        performEndTime: this.$store.state.taskDetailsInfo.performEndTime // 任务执行结束时间
      }

      reportSubTaskDetail(params).then((res) => {
        if (res.data.success) {
          Toast('提交成功')
          // location.href = 'javascript:history.back();'
          this.clearCache()
          this.$router.push('/entrance')
        } else {
          Toast.clear()
          Dialog.alert({
            title: '提示',
            message: res.data.errorMessage,
            confirmButtonText: '我知道了',
            confirmButtonColor: '#24ADFE'
          })
        }
      })
    },
    // 获取详情数据
    async getData() {
      listSubTaskDetail({
        taskSubId: this.$route.params.taskSubId,
        performCode: this.$route.params.code // 这个code就是每道题的performeCode 在提交的时候使用  待改成performeCode   也就是taskPerformCode??
      }).then(async (res) => {
        // 带逻辑 假数据
        // res.data = JSON.parse(JSON.stringify(mock.data1))

        // 针对新增的多图片题进行判断  赋值为itemTypeF=999
        res.data.data.itemList.forEach((el) => {
          // 20230206 移除itemImg字段全部使用 itemImgs
          // if (el.imgMaxCount && el.imgMaxCount !== 1) {
          if (el.itemType === 3) {
            // 20230206 移除itemImg字段全部使用 itemImgs
            // el.itemTypeF = 999
            if (el.itemImgs === null || el.itemImgs === undefined) {
              el.itemImgs = []
            }
            // 添加默认key
            el.itemImgs.forEach((itemImgsItem, itemIndex) => {
              itemImgsItem.key = 'init' + itemIndex
            })
            const length = el.itemImgs.length
            // 补全长度
            for (let index = 0; index < el.imgMinCount - length; index++) {
              el.itemImgs.push({
                relativeUrl: null,
                absoluteUrl: null,
                key: 'blank' + index
              })
            }
          }
        })
        // 如果页面刷新会导致cached变为false 如果缓存的performCode一致则使用缓存数据
        this.logic = res.data.data.logic || []
        this.checkStore()
        const cache = this.$store.state.taskDetailsCache
        if (this.$store.state.noCache.cached === false && this.$route.params.code === cache.performCode) {
          console.log('cached')
          console.log('cache keepDataList', cache.keepDataList)
          console.log('cache dataList', cache.dataList)
          console.log('cache this.logic', this.logic)
          this.keepDataList = cache.keepDataList // 不可使用res.data.data.itemList 因为可能修改过了 比如图片内容 选项内容
          // this.dataList = this.getListByLogic(cache.dataList, this.logic)
          this.dataList = cache.dataList
        } else {
          this.keepDataList = res.data.data.itemList
          this.dataList = this.getListByLogic(this.keepDataList, this.logic)
        }
        setTimeout(() => {
          this.oldDataList = JSON.parse(JSON.stringify(res.data.data.itemList))
        }, 20)
        this.dataListString = JSON.stringify(this.dataList)
        browserPos()
      })
    },
    /** 通过逻辑计算展示的数组
     * 注意!!! 为缓存数据,在数据流转过程中,不可深拷贝数据,谨慎使用数组非变异方法和浅拷贝 如未正确返回结果请检查是否传递过程导致itemOptionCode未正确传入
     * 甄别结束>正常结束>逻辑关联
     * 入参 :
     * allList 必传 全部数据得数组
     * logicList 必传 逻辑数组
     * startCode = null 尚未开发 逻辑关联重计算时节省资源 从什么题目code未多少的位置开始重新计算 若不传则全部重新计算
     * 返回值 : 计算后的数组
     *  **/
    getListByLogic(allList, logicList) {
      console.log('进入逻辑')
      console.log('allList', allList)
      console.log('logicList', logicList)
      // 兼容传入空数组
      // if (allList.length === 0) {
      //   return allList
      // }
      // 如果没有逻辑或者逻辑为空则直接返回原数组
      if (!logicList || logicList.length === 0) {
        return allList
      }
      const res = []
      // 第一题甄别结束 且 尚未回答问题时 只展示第一题
      if (allList[0].earlyEnd === true && !allList[0].itemOptionCode) {
        res.push(allList[0])
        return res
      }
      endLogic()
      // 如果第0项 isEarlyEndAnswer === true 则只展示第一题
      if (allList[0].isEarlyEndAnswer === true) {
        res.push(allList[0])
        return res
      }
      // 非甄别结束情况下 继续处理回答逻辑
      answerLogic()
      // console.log(allList)
      allList.forEach((el) => {
        // showByNormalEndlogic=false正常结束不展示  非正常结束时判断showByInitlogic===false且showByAnswerLogic!==true不展示
        if (el.showByNormalEndlogic !== false) {
          if (el.showByInitlogic !== false || (el.showByInitlogic === false && el.showByAnswerLogic === true)) {
            res.push(el)
          }
        }
      })
      return res

      // 处理回答逻辑
      function answerLogic() {
        const target = []
        // 遍历逻辑数组
        logicList.forEach((logic_El) => {
          // 遍历每个logic_El下的对象 实际应该只有一个键值对
          for (const key in logic_El) {
            if (Object.hasOwnProperty.call(logic_El, key)) {
              const logicElement = logic_El[key]
              /* normalEnd earlyEnd必须按照题号的顺序依次判断 但是逻辑数组不一定是按照顺序排的 所以不能直接在这里遍历 */
              // 遍历logicItemCode数组
              if (logicElement.logicItemCode && logicElement.logicItemCode.length) {
                logicElement.logicItemCode.forEach((logicItemCode_EL) => {
                  // let findItem = allList.find((allList_El) => logicItemCode_EL === allList_El.code)
                  // findItem.showByInitlogic = false
                  allList.forEach((allList_El) => {
                    if (allList_El.code === logicItemCode_EL) {
                      // 初始化逻辑 被逻辑关联的题默认隐藏  showByInitlogic设置为false
                      allList_El.showByInitlogic = false
                      // 两条逻辑同时指向第三题 只要有一条命中就显示第三题
                      // 答案命中
                      const findItem = allList.find((allList_El) => key === allList_El.code)
                      if (checkAnswer(logicElement, findItem)) {
                        // 把命中过得题目code存起来   logicItemCode_EL实际是等于allList_El.code的
                        target.push(logicItemCode_EL)
                        allList_El.showByAnswerLogic = true
                      } else {
                        // 本次答案未命中 且之前也未命中过
                        if (!target.includes(logicItemCode_EL)) {
                          allList_El.showByAnswerLogic = false
                        }
                      }
                    }
                  })
                })
              }
            }
          }
        })
      }

      // 处理 正常结束和甄别结束逻辑
      function endLogic() {
        // 是否遇到NormalEnd的开关
        let isNormalEndFlag = false
        // 遍历allList
        for (let i = 0; i < allList.length; i++) {
          const el = allList[i]
          // console.log(el)
          // earlyEnd 仅针对第0项校验否是earlyEnd
          if (i === 0 && el.earlyEnd === true) {
            if (checkAnswerForEnd(el, 'earlyEnd')) {
              el.isEarlyEndAnswer = true
              break // 跳出循环
            } else {
              el.isEarlyEndAnswer = false
            }
          }

          // 校验normalEnd 默认开关为false 所有的showByNormalEndlogic设置为true  开关变更后showByNormalEndlogic为false
          if (isNormalEndFlag === false) {
            el.showByNormalEndlogic = true
            if (el.normalEnd === true) {
              if (checkAnswerForEnd(el, 'normalEnd')) {
                isNormalEndFlag = true
              }
            }
          } else {
            // 遇到正常结束 直接将后续题目showByNormalEndlogic设置为false
            el.showByNormalEndlogic = false
          }
        }
      }

      // 校验答案是否满足正常结束或者提前结束 type==='earlyEnd' || type==='normalEnd'
      function checkAnswerForEnd(el, type) {
        let flag = false
        logicList.forEach((logic_El) => {
          for (const key in logic_El) {
            if (Object.hasOwnProperty.call(logic_El, key)) {
              const logicElement = logic_El[key]
              // isFinish为earlyEnd 且key值为 传入对象的code
              if (logicElement.isFinish === type && key === el.code) {
                if (checkAnswer(logicElement, el)) {
                  flag = true
                }
              }
            }
          }
        })
        // console.log('checkAnswerForEnd', flag)
        return flag
      }

      // 校验选中和未选中逻辑是否达成 如此逻辑对象 题目对象
      function checkAnswer(logicEl, itemEL) {
        // console.log(logicEl, itemEL)
        let flag = false
        const arr1 = itemEL.itemOptionCode ? itemEL.itemOptionCode.split('#').sort() : []
        const arr2 = logicEl.optionalCodeList.sort()
        // console.log(arr1, JSON.stringify(arr1))
        // console.log(arr2, JSON.stringify(arr2), logicEl.type, arr1.length)
        // console.log(JSON.stringify(arr1) !== JSON.stringify(arr2))
        if (JSON.stringify(arr1) === JSON.stringify(arr2) && logicEl.type === 'selected') {
          flag = true
        }
        // logicEl.type==='unselected'的情况  若未选中AB则表示  有选项且选中的刚好不是AB
        if (JSON.stringify(arr1) !== JSON.stringify(arr2) && logicEl.type === 'unselected' && arr1.length > 0) {
          flag = true
        }
        // console.log('checkAnswer', flag)
        return flag
      }
    },
    // 计算禁用状态
    computedDisabled() {
      // 此处应该校验是否每道题都有答案
      let noAnswer = false
      for (let index = 0; index < this.dataList.length; index++) {
        const element = this.dataList[index]
        // 问答
        if (element.itemType === 0 || element.itemType === 5) {
          if (element.itemAnswer === null || element.itemAnswer === undefined || element.itemAnswer === '') {
            noAnswer = true
            break
          }
        }
        // 单选
        if (element.itemType === 1) {
          if (
            element.itemOptionCode === null ||
            element.itemOptionCode === undefined ||
            element.itemOptionCode === ''
          ) {
            noAnswer = true
            break
          }
        }
        // 多选
        if (element.itemType === 2) {
          if (
            element.itemOptionCode === null ||
            element.itemOptionCode === undefined ||
            element.itemOptionCode === ''
          ) {
            noAnswer = true
            break
          }
        }
        // 图片
        // 20230206 移除itemImg字段全部使用 itemImgs
        // if (element.itemType === 3 && element.itemTypeF === 999) {
        if (element.itemType === 3) {
          let noAbsoluteUrl = false
          for (let index = 0; index < element.itemImgs.length; index++) {
            const item = element.itemImgs[index]
            if (
              index < element.imgMinCount &&
              (item.absoluteUrl === '' || item.absoluteUrl === null || item.absoluteUrl === undefined)
            ) {
              noAbsoluteUrl = true
              break
            }
          }
          if (noAbsoluteUrl) {
            noAnswer = true
            break
          }
        }
        // 20230206 移除itemImg字段全部使用 itemImgs
        // else if (element.itemType === 3) {
        //   if (element.itemImg === null || element.itemImg === undefined || element.itemImg === '') {
        //     noAnswer = true
        //     break
        //   }
        // }
      }
      if (
        !this.checkChange() || // 未变更内容
        this.$store.state.lng == '' || // 未获取到定位 需要打开
        this.$store.state.loadingStatus || // 图片正在上传中
        noAnswer || // 是否有未回答的题
        this.$store.state.positionLoading // 正在定位中
      ) {
        return true
      } else {
        return false
      }
    },
    // 校验是否有变更题目
    checkChange() {
      // 剔除多图片上传中的空上传框
      const nowDataList = JSON.parse(JSON.stringify(this.dataList))
      nowDataList.forEach((el) => {
        if (el.itemType === 3) {
          el.itemImgs = el.itemImgs.filter((data) => data.relativeUrl)
        }
      })
      // 如果是没有做过的题 即使没改变内容也提示
      if (this.$store.state.taskDetailsInfo.isDone && JSON.stringify(nowDataList) === this.dataListString) {
        return false
      } else {
        return true
      }
    },
    // 重新计算 异常状态 和 页面尺寸
    resetMounted() {
      // 小于800 或者门店无经纬度
      if (
        this.$store.state.distance <= 800 ||
        this.$store.state.userInfo.terminalLongitude == null ||
        this.$store.state.userInfo.terminalLatitude == null
      ) {
        this.posError = false
      } else {
        this.posError = true
      }
      setTimeout(() => {
        // 重新计算页面尺寸
        if (document.querySelector('.posBox')) {
          this.height = window.innerHeight - 44 - 65 - document.querySelector('.posBox').clientHeight + 'px'
        } else {
          this.height = window.innerHeight - 44 - 65 + 'px'
        }
      }, 0)
    },
    // 展示距离  无门店左边0.1  无定位N 其他正常
    showDistance() {
      if (this.$store.state.lng === 0 || this.$store.state.lat === 0) {
        return '与门店偏差距离 n 米'
      } else if (
        this.$store.state.userInfo.terminalLongitude == null ||
        this.$store.state.userInfo.terminalLatitude == null
      ) {
        return '门店没有GPS'
      } else {
        return `与门店偏差距离 ${this.$store.state.distance} 米`
      }
    },
    // 重新计算页面尺寸
    resizeHeight() {
      if (document.querySelector('.posBox')) {
        this.height = window.innerHeight - 44 - 65 - document.querySelector('.posBox').clientHeight + 'px'
      } else {
        this.height = window.innerHeight - 44 - 65 + 'px'
      }
    },
    // 获取用户 个人照片采集授权
    async registerPhoto() {
      // 发请求
      if (this.$store.state.taskCode == 'KSZP') {
        const res = await checkAccordProvide()
        if (res.data.data == '未授权') {
          this.showRegisterPhoto = true
        }
      }
    },
    // 展示协议弹窗
    showAgreement() {
      this.$refs.AgreementPhoto.show = true
    },
    // 同意协议
    async confirmRegister() {
      this.checkedRegisterPhoto = true
      const res = await accordProvide()
      if (res.data.success) {
        this.showRegisterPhoto = false
      }
    },
    // 获取设备信息
    async getUserAgent() {
      // //调用上传设备信息 ip接口 用户信息
      var output = new UA(navigator.userAgent) // UA获取到的对象
      var sss = navigator.userAgent.split(';')
      // 判断UA里边有没有Build信息，通过这个拿到安卓的具体机型
      var i = -1
      sss.forEach((element, index) => {
        if (element.includes('Build/')) {
          i = index
        }
      })
      let newMobile // 如果是安卓手机返回安卓的型号
      if (i > -1) {
        newMobile = sss[i].substring(0, sss[i].indexOf('Build/'))
      }
      // console.log(output.device.identified ? output.device.model : newMobile)//设备型号
      // console.log(output.browser.name + ' ' + output.browser.version.original)//浏览器版本
      // console.log(output.os.name + ' ' + output.os.version.original)//系统版本
      let ip = ''
      try {
        ip = returnCitySN.cip
      } catch {}
      await addHardwareLog({
        funTitle: this.$store.state.taskDetailsInfo.taskSubCode + this.$store.state.taskDetailsInfo.title,
        userAgents: navigator.userAgent,
        resolvingPower: `${screen.width}x${window.screen.height}`,
        ip: ip,
        userCode: this.$store.state.openOrScheduleId.openId, // 传的字段是userCode实际传的是openId
        browser: output.browser.name + ' ' + output.browser.version.original,
        os: output.os.name + ' ' + output.os.version.original,
        device: output.device.identified ? output.device.model : newMobile
      })
    },
    // 初始化页面
    async init() {
      this.show = true
      this.$gobal.controlOptionMenu()
      this.resetMounted()
      // 添加键盘监听事件
      this.defaultPhoneHeight = window.innerHeight
      window.onresize = () => {
        this.nowPhoneHeight = window.innerHeight
      }
      await this.registerPhoto()
      this.getData()
      this.getUserAgent()
    },
    async toFace(url, message, protocolState) {
      const sdk = await this.$store.state.getSdk('CRMEX-TXFACEVERIFY', () => {
        this.$router.push('/entrance')
      })
      if (sdk) {
        if(protocolState == '1') {
          location.href = url
        } else {
          Dialog.alert({
            title: '提示',
            message: message || '该页面服务由第三方“腾讯云计算（北京）有限责任公司”提供',
            confirmButtonText: '我知道了',
            confirmButtonColor: '#24ADFE'
          }).then(() => {
            location.href = url
          })
        }
      }
    },
    // 浏览器被kill后貌似不会重新走mounted 尝试异步中执行
    checkStore() {
      try {
        // console.log('try')
        // console.log('state', this.$store.state)
        // console.log('state.noCache.cached', this.$store.state.noCache.cached)
        // console.log('Dialog', Dialog)
        if (this.$store.state.noCache.cached === false) {
          Dialog({
            title: '提示',
            message: '如遇到拍照后页面刷新无法正常上传图片，可能是您的手机后台应用过多、电量过低等情况导致手机内存不足，建议清空手机其他的后台应用后再进行照片上传操作',
            confirmButtonText: '确定'
            // confirmButtonColor: 'black'
          }).then(() => {})
        }
      } catch {
        // console.log('catch')
        // console.log(this.$store.state)
        // console.log(this.$store.state.noCache.cached)
      }
    },
    // 微信浮窗功能有可能导致同时存在两个页面,缓存的数据和页面原本的数据不一致,导致提交数据异常.
    checkStoreDetail() {
      return new Promise((resolve, reject) => {
        // 校验 当前路由的任务code 和store中的完全一致  两处的title也完全一致
        const codeRoute = this.$route.params.code
        const codeVuex = this.$store.state.taskDetailsInfo.code
        const titleState = this.$store.state.taskTitle
        const titleTaskDetailsInfo = this.$store.state.taskDetailsInfo.moduleTitle
        // console.log(codeRoute, codeVuex, titleState, titleTaskDetailsInfo)
        if (codeRoute === codeVuex && titleState === titleTaskDetailsInfo) {
          resolve(true)
        } else {
          Dialog({
            title: '提示',
            message: '数据异常,请勿在浮窗中进行任务提报,请关闭页面后重新在公众号进入再进行操作',
            confirmButtonText: '确定'
            // confirmButtonColor: 'black'
          }).then(() => {
            setTimeout(function () {
              document.addEventListener(
                'WeixinJSBridgeReady',
                function () {
                  this.WeixinJSBridge.call('closeWindow') // 安卓手机关闭代码
                },
                false
              )
              this.WeixinJSBridge.call('closeWindow') // 苹果手机关闭代码
            }, 300)
            // 普通浏览器关闭代码
            window.opener = null
            window.open('about:blank', '_top').close()
          })
          reject(false)
        }
      })
    }
  },
  computed: {
    // 监听是否重新计算距离
    watch_distance() {
      return this.$store.state.resetSize
    }
  },
  watch: {
    watch_distance() {
      setTimeout(() => {
        this.resetMounted()
      }, 300)
    },
    nowPhoneHeight: function () {
      if (this.defaultPhoneHeight != this.nowPhoneHeight) {
        // 手机键盘被唤起了。
        this.resizeHeight()
      } else {
        // 手机键盘被关闭了。
        this.resizeHeight()
      }
    }
  },
  destroyed() {
    window.onresize = null
  },
  async mounted() {
    // 禁用返回键
    this.$store.state.disableBack()
    // 初始化刷脸逻辑
    const taskSubId = this.$route.params.taskSubId
    const performCode = this.$route.params.code
    const userCode = this.$store.state.userInfo.userCode
    const faceTaskLogId = this.$route.query.faceTaskLogId
    const res = await getFace({ taskSubId, performCode, userCode, faceTaskLogId }).catch(() => {
      setTimeout(() => {
        this.$router.push('/entrance')
      }, 2000)
    })
    if (res.data.data.isTimeLimit) {
      // 如果超过验证次数
      Dialog.alert({
        title: '提示',
        message: '您本日的激活使用次数已超限',
        confirmButtonText: '我知道了',
        confirmButtonColor: '#24ADFE'
      }).then(() => {
        this.$router.push('/entrance')
      })
    } else if (res.data.data.isUnqualified) {
      // 如果人脸识别未成功
      Dialog.confirm({
        title: '提示',
        message: '您上次激活任务并未通过,请重新前往激活',
        confirmButtonText: '前往激活',
        cancelButtonText: '返回上级',
        cancelButtonColor: 'black',
        confirmButtonColor: 'black'
      })
        .then(() => {
          this.toFace(res.data.data.faceCompareUrl, res.data.data.msgText, res.data.data.protocolState)
        })
        .catch(() => {
          this.$router.push('/entrance')
        })
    } else if (!res.data.data.isPassed) {
      // 如果有路径就代表需要激活
      this.toFace(res.data.data.faceCompareUrl, res.data.data.msgText, res.data.data.protocolState)
    } else {
      // 不需要激活直接渲染页面
      this.init()
    }
  }
}
</script>
<style lang="less" scoped>
.flexBox {
  display: flex;
  align-items: flex-start;
}
.taskDetails {
  position: relative;
}
.btnBox {
  opacity: 0.92;
  width: 94.6%;
  height: 45px;
  position: absolute;
  bottom: 0;
  background: #fff;
  padding: 10px 2.7%;
  display: flex;
  button {
    width: 50%;
  }
  .submit {
    border-radius: 0 22px 22px 0;
  }
  .cancel {
    border-radius: 22px 0 0 22px;
    background: #e6fbff;
    border: 1px solid #76d7f5;
    color: #1a99ff;
  }
}
.posBox {
  line-height: 24px;
  min-height: 24px;
  padding: 13px 4% 13px 5.6%;
  font-size: 14px;
  .location-o {
    font-size: 16px;
  }
  .btn {
    padding: 5px;
    background: #fdeceb;
    color: #ff4d4f;
    font-size: 12px;
    margin-left: 10px;
    border: 1px solid #ffbab7;
    border-radius: 2px;
    width: 80px;
    height: 20px;
    line-height: 20px;
  }
}
.cardBox {
  background: #fff;
  margin-bottom: 10px;
  padding: 15px 5.3% 20px 5.3%;
  color: #333333;
}
.required {
  color: #ff4d4f;
  margin-right: 5px;
}
.tips {
  background: #f6ffed;
  border: 1px solid #b7eb8f;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  color: #52c41a;
  font-weight: 400;
  margin-left: 10px;
  padding: 3px 13px;
  display: inline-block;
}
.tipsnotstart {
  background: #ffffff;
  border: 1px solid #9da4b1;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  color: #9da4b1;
  font-weight: 400;
  margin-left: 10px;
  padding: 3px 13px;
  display: inline-block;
}
.tipsend {
  background: #ffffff;
  border: 1px solid #ff6633;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  color: #ff6633;
  font-weight: 400;
  margin-left: 10px;
  padding: 3px 13px;
  display: inline-block;
}
.tipsgoon {
  background: #ffffff;
  border: 1px solid #4badff;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  color: #4badff;
  font-weight: 400;
  margin-left: 10px;
  padding: 3px 13px;
  display: inline-block;
}
.tittleBox {
  color: #333333;
  font-weight: 900;
}
.describe {
  color: #666666;
  text-align: justify;
}
.taskTittle {
  color: #333333;
  text-align: justify;
}
.disabledSub {
  background: #ccc;
  border-color: #ccc;
}
.taskDetails .van-action-sheet__header {
  font-size: 18px;
  color: #333333;
  text-align: center;
  line-height: 60px;
  font-weight: 900;
  background: #fafafa;
}
.example {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;
  .arrow {
    position: relative;
    top: 2px;
    margin-left: 13px;
  }
}
</style>
